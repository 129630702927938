import React from "react"
import { StaticQuery, graphql, Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

import SafeAreaWrapper from "./SafeAreaWrapper"
import Container from "./Container"
import Grid from "./Grid"

const RecentNewFeatures = ({}) => {
	return (
		<StaticQuery
			query={graphql`
				query {
					recentNewFeature: allMongodbBloghanseaticsoftArticles(
						sort: { order: DESC, fields: published_at }
						limit: 2
						filter: {
							tags: { elemMatch: { name: { in: ["New Feature", "New Module"] } } }
						}
					) {
						edges {
							node {
								id
								title
								published_at(formatString: "MMMM DD, YYYY")
								slug
								teaser_image {
									imgixImage {
										gatsbyImageData(layout: CONSTRAINED, width: 650)
									}
								}
							}
						}
					}
				}
			`}
			render={data => (
				<div className="bg-white pt-spacer-xl">
					<SafeAreaWrapper>
						<Container className="pb-spacer-md">
							<h1 className="display1">Monthly releases</h1>
						</Container>
						<Grid className="gap-y-40">
							{data.recentNewFeature.edges.map(edge => (
								<div key={edge.node.id} className="col-span-12 lg:col-span-6">
									<Link
										to={`/blog/${edge.node.slug}`}
										alt={edge.node.title}
										title={edge.node.title}>
										<div className="rounded-medium overflow-hidden h-[300px] relative z-10">
											<GatsbyImage
												className="w-full h-[300px] cursor-pointer duration-500 hover:scale-125"
												imgClassName="rounded-medium overflow-hidden "
												image={
													edge.node.teaser_image.imgixImage
														.gatsbyImageData
												}
												alt={edge.node.title}
												title={edge.node.title}
											/>
										</div>
										<h2 className="display2 mt-18">{edge.node.title}</h2>
									</Link>
								</div>
							))}
						</Grid>
					</SafeAreaWrapper>
				</div>
			)}
		/>
	)
}

export default RecentNewFeatures
